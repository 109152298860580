import React, { Component } from "react"

export default class Services extends Component {
  render() {
    return (
      <div className="services hero-blue">
        <section className="wrap">
          <h2>Mes services</h2>
          <div className="services-container">
            <div className="service">
              <section>
                <h3>Création de site internet</h3>
                <p>
                  Création complète ou refonte d’un site actuel, le résultat
                  sera pensé et optimisé afin de satisfaire vos visiteurs.
                </p>
              </section>
              <i className="icon-develop"></i>
            </div>

            <div className="service">
              <section>
                <h3>Maquettage, design</h3>
                <p>
                  L’aspect de votre site ne vous convient plus ? Vous souhaitez
                  qu’il retrouve une seconde jeunesse ? Cela passe par la case
                  design, avec une refonte graphique !
                </p>
              </section>
              <i className="icon-design"></i>
            </div>

            <div className="service">
              <section>
                <h3>Hébergement</h3>
                <p>
                  Propriétaire d’un site ou d’une application web sans
                  hébergement ? Offrons un nouveau toit à votre projet !
                </p>
              </section>
              <i className="icon-host"></i>
            </div>

            <div className="service">
              <section>
                <h3>Développement</h3>
                <p>
                  Vous souhaitez rajouter une fonctionnalité à votre site ?
                  Carte, formulaire de contact, nouvelles pages ?
                </p>
              </section>
              <i className="icon-analytics"></i>
            </div>
          </div>
        </section>
        <a href="mailto:julien.taret.f@gmail.com" className="btn btn-secondary">
          Un projet ? Contactez-moi
        </a>
      </div>
    )
  }
}
