import React, { Component } from "react"

export default class Skills extends Component {
  render() {
    return (
      <section className="skills hero-coral">
        <div className="wrap">
          <h2>Compétences</h2>
          <div className="skills-container">
            <p>HTML 5</p>
            <p>CSS 3</p>
            <p>JavaScript (ES6, ES7)</p>
            <p>PHP</p>
            <p>Less</p>
            <p>Sass</p>
            <p>Git</p>
            <p>React</p>
            <p>React Native</p>
            <p>Gatsby JS</p>
            <p>Dart</p>
            <p>Flutter</p>
            <p>Adobe XD</p>
            <p>SEO</p>
          </div>
          <p className="github">
            Retrouvez certains de mes projets sur{" "}
            <a href="https://github.com/julientaret" target="_blank">
              GitHub
            </a>
          </p>
        </div>
      </section>
    )
  }
}
