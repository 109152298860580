import React, { Component } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default class AboutMe extends Component {
  render() {
    return (
      <section className="about-me wrap">
        <h2>
          Qui je suis, <br />
          ce que je fais
        </h2>
        <div>
          <p>
            <strong>
              Intégrateur et développeur Front-End pour le compte d’une agence
              Web, je met également mes compétences au service de clients à la
              recherche de solutions fiables et performantes pour leur projet.
              N'hésitez pas à me contacter pour étudier ensemble votre projet.
            </strong>
          </p>
          <p>
            Curieux de nature, j'ai ce besoin de sans cesse augmenter mes
            compétences, la stagnation me faisant horreur, quel que soit le
            domaine. Mon travail au quotidien depuis bientôt deux ans me permet
            aujourd’hui de répondre aux exigences de la création de solutions
            sur internet.
          </p>
        </div>
      </section>
    )
  }
}
