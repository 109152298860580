import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutMe from "../components/AboutMe"
import Contact from "../components/Contact"
import Works from "../components/Works"
import Services from "../components/Services"
import Skills from "../components/Skills"

const IndexPage = () => (
  <Layout>
    <SEO title="Julien Taret" />
    <AboutMe />
    <Services />
    <Works />
    <Skills />
    <Contact />
  </Layout>
)

export default IndexPage
