import React, { Component } from "react"
import SocialBar from "./SocialBar"

export default class Contact extends Component {
  render() {
    return (
      <div className="contact ">
        <div className="wrap">
          <h2>
            Vous avez un projet, un besoin ou simplement besoin d'échanger et
            d'obtenir des conseils ?
          </h2>

          <div className="contact-content">
            <a className="btn-secondary" href="mailto:julien.taret.dev@gmail.com">
              Contactez moi
            </a>
            <p>Ou bien retrouvez moi ici :</p>
            <SocialBar />
          </div>
        </div>
      </div>
    )
  }
}
