import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

class Works extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <section className="Works-home">
        <h2>Réalisations</h2>
        <div className="works-container wrap">
          {posts &&
            posts.map(({ node: post }) => (
              <a href={post.frontmatter.url} key={post.id} target="_blank">
                <Img fixed={post.frontmatter.Image.childImageSharp.fixed} />
                <div className="description-content">
                  <p className="title">{post.frontmatter.title}</p>
                  <p className="description">{post.frontmatter.description}</p>
                </div>
              </a>
            ))}
        </div>
      </section>
    )
  }
}

Works.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "Works" } } }
        ) {
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              frontmatter {
                templateKey
                path
                title
                description
                url
                date(formatString: "DD/MM/YYYY")
                Image {
                  childImageSharp {
                    fixed(width: 300, height: 300) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Works data={data} count={count} />}
  />
)
